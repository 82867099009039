import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Calender from '../components/Calender';
import Topbar from "../components/Topbar";

const CalenderViewPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Acandemic Calender">
            <Topbar />
            <NavOne />
            <PageHeader title="Acandemic Calender"/>
            <Calender />
            <Footer />
        </Layout>
    );
};

export default CalenderViewPage;
